/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import theme from '../views/theme';
//import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";


//import Header from "./header"
import "../views/index.scss"

//const muiTheme = createMuiTheme(theme);

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout;
